<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <div class="content">
        <el-descriptions class="margin-top" title="个人信息" column="2" border>
          <el-descriptions-item label="用户名">{{ info.user_name }}</el-descriptions-item>
          <el-descriptions-item label="用户组">{{ info.group_name }}</el-descriptions-item>
          <el-descriptions-item label="注册邮箱">{{ info.email }}</el-descriptions-item>
          <el-descriptions-item label="用户积分">{{ info.integral }}</el-descriptions-item>
          <el-descriptions-item label="注册时间">{{ info.register_time }}</el-descriptions-item>
          <el-descriptions-item label="最后登录时间">{{ info.last_login_time }}</el-descriptions-item>
          <el-descriptions-item label="操作">
            <el-button type="primary" @click="show_change_password">修改密码</el-button>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
  <el-dialog v-model="is_show_change_password" title="修改密码" width="30%">
    <el-form ref="=PasswordFrom" :model="change_password" :rules="change_password_rules">
      <el-form-item label="旧密码" prop="password">
        <el-input v-model="change_password.password" />
      </el-form-item>
      <el-form-item label="新密码" prop="new_password">
        <el-input v-model="change_password.new_password" />
      </el-form-item>
      <el-form-item label="确认新密码" prop="confirm_new_password">
        <el-input v-model="change_password.confirm_new_password" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close_change_password">取消</el-button>
        <el-button type="primary" @click="change_user_password">提交</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {get_my_info, user_modify_password} from "../../api/api";
import {warning_hint, success_hint} from "../../tool/tools";
import md5 from "js-md5";

export default {
  name: "Info",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;");
  },
  mounted() {
    this.obtain_my_info();
  },
  data(){
    return {
      info: {},
      change_password: {
        password: null,
        new_password: null,
        confirm_new_password: null
      },
      change_password_rules: {
        password: [
          {required: true, message: "请输入旧密码", trigger: "blur"}
        ],
        new_password: [
          {required: true, message: "请输入新密码", trigger: "blur"}
        ],
        confirm_new_password: [
          {required: true, message: "请输入确认新密码", trigger: "blur"}
        ]
      },
      is_show_change_password: false
    }
  },
  methods: {
    show_change_password(){
      this.is_show_change_password = true;
    },
    close_change_password(){
      this.is_show_change_password = false;
    },
    change_user_password(){
      if(this.change_password.new_password !== this.change_password.confirm_new_password){
        warning_hint("密码和确认密码不一致");
      }
      user_modify_password({
        old_password: md5(this.change_password.password).toUpperCase(),
        new_password: md5(this.change_password.new_password).toUpperCase()
      }).then(response => {
        if(response.Code === 1){
          this.close_change_password();
          success_hint("密码修改成功");
        }
        this.clear_change_user_password();
      });
    },
    clear_change_user_password(){
      this.change_password.password = null;
      this.change_password.new_password = null;
      this.change_password.confirm_new_password = null;
    },
    obtain_my_info(){
      get_my_info().then(response => {
        if(response.Code === 1){
          console.log(response);
          this.info = response.Data;
        }
      });
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
@import "../../static/universal/index.css";

.content{
  background: white;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  text-align: center;
}
</style>